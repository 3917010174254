import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import electronics from './categoryImages/electronics.jpg';
import fashion from './categoryImages/fashion.jpg';
import beauty from './categoryImages/beauty.png';
import fruit from './categoryImages/fruit.jpg';
import './style.css';

function ProductCategoryDropdown() {
    return (
    <div class="category">
        <table>
                <tr>
                    <th class="cat-item"><img class="cat-img" src={electronics} width="20%" height="50%" alt="electronics"/></th>
                    <th class="cat-item"><img class="cat-img" src={fashion} width="20%" height="50%" alt="fashion"/></th>
                    <th class="cat-item"><img class="cat-img" src={beauty} width="20%" height="50%" alt="beauty"/></th>
                    <th class="cat-item"><img class="cat-img" src={fruit} width="20%" height="50%" alt="fruit"/></th>
                    <th class="cat-item"><img class="cat-img" src={electronics} width="20%" height="50%" alt="electronics"/></th>
                    <th class="cat-item"><img class="cat-img" src={fashion} width="20%" height="50%" alt="fashion"/></th>
                    <th class="cat-item"><img class="cat-img" src={beauty} width="20%" height="50%" alt="beauty"/></th>
                    <th class="cat-item"><img class="cat-img" src={fruit} width="20%" height="50%" alt="fruit"/></th>
                </tr>
                <tr class="cat-text">
                    <td>Electronics</td>
                    <td>Fashion</td>
                    <td>Beauty</td>
                    <td>Fruit</td>
                    <td>Electronics</td>
                    <td>Fashion</td>
                    <td>Beauty</td>
                    <td>Fruit</td>
                </tr>
        </table>
        
    </div>
    );
}

export default ProductCategoryDropdown;
