import React, { Fragment } from "react";
// import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import home from './Footer-images/home.svg';
import search from './Footer-images/search.svg';
import favorites from './Footer-images/favorites.svg';
import inbox from './Footer-images/inbox.svg';
import account from './Footer-images/account.svg';

const Footer = (props) => {
  return (
    <Fragment>
          {/* <div> */}
            <div class="container-footer">
              {/* Sticky ad */}
              <div class="ad">
                  <h6 style={{textAlign: "center"}}>Ad space</h6>
              </div>
              
              {/* Navigation bar*/}
              <div class="row nav-bar">

                <div class="col">
                  <div class="img-row">
                    <img class="foot-img" src={home} height="20" width="20" alt="home"/>
                  </div>
                  <div class="text-row">
                    <h6 class="foot-text">Home</h6>
                  </div>
                </div>

                <div class="col">
                  <div class="img-row">
                    <img class="foot-img" src={search} height="20" width="20" alt="search"/>
                  </div>
                  <div class="text-row">
                    <h6 class="foot-text">Search</h6>
                  </div>
                </div>

                <div class="col">
                  <div class="img-row">
                    <img class="foot-img" src={favorites} height="20" width="20" alt="favorites"/>
                  </div>
                  <div class="text-row">
                    <h6 class="foot-text">Fav</h6>
                  </div>
                </div>

                <div class="col">
                  <div class="img-row">
                    <img class="foot-img" src={inbox} height="20" width="20" alt="inbox"/>
                  </div>
                  <div class="text-row">
                    <h6 class="foot-text">Inbox</h6>
                  </div>
                </div>

                <div class="col">
                  <div class="img-row">
                    <img class="foot-img" src={account} height="20" width="20" alt="account"/>
                  </div>
                  <div class="text-row">
                    <h6 class="foot-text">Account</h6>
                  </div>
                </div>
                    
              </div>
            </div>
          {/* </div> */}

       {/* <footer */}
        {/* style={{background: "white", color: "#87898A", bottom: "0", */}
    {/* }} */}
        {/* className="z-10 py-6 px-4 md:px-12 text-center" */}
      {/* > */}
        {/* Sellular © Copyright {moment().format("YYYY")} */}
      {/* </footer>  */}
      
    </Fragment>
  );
};

export default Footer;