import React from "react";
import { Navber } from "../partials";
import { Footer } from "../partials";
import ProductsAds from "./ProductsAds";
import ProductsAdsSec from "./ProductsAdsSec";

function CategoryListing() {
    return (
        <div>

            <Navber />

            <ProductsAds />
            <ProductsAds />
            {/* <ProductsAdsSec /> */}

            <Footer />

        </div>
    );
}

export default CategoryListing;