import React from 'react';
import nike from './featuredImages/nike.png';
import beauty1 from './beauty-images/beauty1.png'
import beauty2 from './beauty-images/beauty2.png'
import beauty3 from './beauty-images/beauty3.png'
import beauty4 from './beauty-images/beauty4.png'
import beauty5 from './beauty-images/beauty5.png'

import './style.css';

function AllProducts() {
    return (
        <div>

        
        {/* Electronics Products */}
        <div className='all-products'>

            <div className='product-header'>
                <div className='recommended-for-you'>
                    Electronics
                </div>
                <div className='view-all'>
                    View all
                </div>
            </div>

            <div className='featured-products'>
                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>
            </div>
            
        </div>

        {/* Fashion Products */}
        <div className='all-products'>

            <div className='product-header'>
                <div className='recommended-for-you'>
                    Fashion
                </div>
                <div className='view-all'>
                    View all
                </div>
            </div>

            <div className='featured-products'>
                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>
            </div>
            
        </div>


        {/* Beauty Products */}
        <div className='all-products'>

            <div className='product-header'>
                <div className='recommended-for-you'>
                    Beauty
                </div>
                <div className='view-all'>
                    View all
                </div>
            </div>

            <div className='featured-products'>
                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={beauty1} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={beauty2} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={beauty3} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={beauty4} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={beauty5} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>
            </div>
            
        </div>


        {/* Fruit Products */}
        <div className='all-products'>

            <div className='product-header'>
                <div className='recommended-for-you'>
                    Fruits
                </div>
                <div className='view-all'>
                    View all
                </div>
            </div>

            <div className='featured-products'>
                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>

                <div className='product-container border-solid border-2'>
                    <div className='featured-img h-40 w-40'>
                        <img src={nike} alt="featured-product-img"/>
                    </div>
                    <div className='featured-text text-center'>
                        <h6>Shoe!</h6>
                    </div>
                </div>
            </div>
            
        </div>

        </div>
    );
}

export default AllProducts